import React from "react";
import { Link } from "gatsby";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalSector = (props) => {
  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg">
        <ModalHeader toggle={props.toggle}>
          {" "}
          {props.building && props.building.name ? props.building.name : ""}
        </ModalHeader>
        <ModalBody>
            <table class="table table-bordered">
              {props.building && props.building.status ? (
                  <tr>
                    <th style={{width: "11em"}}>Stav:</th>
                    <td>{props.building.status}</td>
                  </tr>
              ) : ("")}

              {props.building && props.building.area ? (
                  <tr>
                    <th>Rozloha&nbsp;pozemku:</th>
                    <td>{props.building.area}<sup>2</sup></td>
                  </tr>
              ) : ("")}
              {props.building && props.building.building ? (
                  <tr>
                    <th>Rozloha&nbsp;stavby:</th>
                    <td>{props.building.building}<sup>2</sup></td>
                  </tr>
              ) : ("")}
            </table>

            {props.building && props.building.sentence1 ? (<div>{props.building.sentence1}</div>) : ("")}
            {props.building && props.building.sentence2 ? (<div>{props.building.sentence2}</div>) : ("")}
            {props.building && props.building.sentence3 ? (<div>{props.building.sentence3}</div>) : ("")}
            {props.building && props.building.sentence4 ? (<div class="strong">{props.building.sentence4}</div>) : ("")}

        </ModalBody>
        <ModalFooter>
          {props.building && props.building.more ? (
              <Link className="btn btn-success" to={"/architektura/" + props.building.more}>
                Viac informácii
              </Link>
          ) : (
            ""
          )}
          <Button color="secondary" onClick={props.toggle}>
            Zavrieť
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalSector;
